import React, { useEffect, useRef, useState } from "react";
import BgImage from "../../Assets/images/bg_blog.png";
import BgLeft from "../../Assets/images/vector_1.png";
import BgRight from "../../Assets/images/vector_2.png";
import { IoMdSearch } from "react-icons/io";
import BlogCard from "./components/BlogCard";
import "./Blogs.scss";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getBlogs, GetCategoryList } from "../../apiCall";
import { CACHE_TIME } from "../../Constants";
import LodingBlogCard from "./components/LodingBlogCard";

const dummyData = [
  {
    title:
      "Which Businesses Need PPC Advertising? A Guide to Maximizing Benefits",
    author_name: "Keyur Acharya",
    publish_date: "31 December, 2024",
    publish_time: "11:30 PM",
  },
  {
    title:
      "Which Businesses Need PPC Advertising? A Guide to Maximizing Benefits",
    author_name: "Keyur Acharya",
    publish_date: "31 December, 2024",
    publish_time: "11:30 PM",
  },
  {
    title:
      "Which Businesses Need PPC Advertising? A Guide to Maximizing Benefits",
    author_name: "Keyur Acharya",
    publish_date: "31 December, 2024",
    publish_time: "11:30 PM",
  },
  {
    title:
      "Which Businesses Need PPC Advertising? A Guide to Maximizing Benefits",
    author_name: "Keyur Acharya",
    publish_date: "31 December, 2024",
    publish_time: "11:30 PM",
  },
  {
    title:
      "Which Businesses Need PPC Advertising? A Guide to Maximizing Benefits",
    author_name: "Keyur Acharya",
    publish_date: "31 December, 2024",
    publish_time: "11:30 PM",
  },
];
export default function Blogs() {
  const [category, setCategory] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const searchRef = useRef(null);
  const setQuery = () => {
    const value = searchRef.current.value;
    setSearchQuery(value);
  };
  const { isLoading: isCategoryLoading } = useQuery(
    ["blog-category"],
    () => GetCategoryList(),
    {
      // staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
      onSuccess: (data) => {
        if (data.data?.status[0].Error === "False") {
          setCategory(data.data.value);
        }
      },
      onError: (err) => {
        // console.log(err, "ERROR");
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("Something went wrong");
        }
      },
    }
  );
  const { isLoading: isBlogLoding } = useQuery(
    ["blogs", searchQuery, selectedCategory],
    () => getBlogs(selectedCategory, "", searchQuery),
    {
      // staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
      onSuccess: (data) => {
        if (data.data?.status[0].Error === "False") {
          setBlogs(data.data.value);
        }
      },
      onError: (err) => {
        // console.log(err, "ERROR");
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("Something went wrong");
        }
      },
    }
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="blog-list">
      <div className="wrapper">
        <section className="section-banner">
          <img src={BgImage} alt="" className="bg-image" />
          <img src={BgLeft} alt="" className="bg-left" />
          <img src={BgRight} alt="" className="bg-right" />
          <div className="content-wrapper">
            <h1>Blogs</h1>
            <p>
              We’re a full-scale digital marketing agency to unlock your digital
              potential. With strategic digital marketing services, we create
              remarkable experiences to expand your reach across targeted
              channels.
            </p>
          </div>
        </section>

        <section className="section-blog-list ">
          <div className="filter">
            <div className="select-wrapper">
              <select
                onChange={(event) => setSelectedCategory(event.target.value)}
              >
                <option value={""}>All Category</option>
                {category.map((item) => (
                  <option value={item.id}> {item.title}</option>
                ))}
              </select>
            </div>
            <div className="input-wrapper">
              <input
                type="text"
                placeholder="Search here"
                ref={searchRef}
                onChange={(event) => {
                  if (event.target.value === "") {
                    setSearchQuery("");
                  }
                }}
              ></input>
              <div className="search-icon " onClick={() => setQuery()}>
                <IoMdSearch size={24} fill="white" />
              </div>
            </div>
          </div>
          <div className="blogs">
            {isBlogLoding ? (
              <>
                <LodingBlogCard />
                <LodingBlogCard />
                <LodingBlogCard />
                <LodingBlogCard />
              </>
            ) : (
              <>
                {blogs.map((data, index) => (
                  <BlogCard data={data} key={`blog-${index}`} />
                ))}
              </>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}
