import { api } from "./Constants";
import { request } from "./utils/axios-utils";

// ***************************************************  Create User  ****************************************************************
export async function SignUp(data) {
  return request({ url: api.clientSignup, method: "post", data: data });
}

// ***************************************************  Login  ****************************************************************
export async function SignIn(data) {
  return request({ url: api.login, method: "post", data: data });
}
export const googleLogin = (googleToken) => {
  return request({
    url: api.google_login,
    method: "post",
    data: {
      access_token: googleToken,
    },
  });
};

// ***************************************************  update user data  ****************************************************************
export async function UpdateUser(data) {
  return request({ url: api.update_user, method: "patch", data: data });
}
// ***************************************************  Feedback data  ****************************************************************
export async function FeedbackData(data) {
  return request({ url: api.feedback, method: "post", data: data });
}

// ***************************************************  update user image  ****************************************************************
export async function UpdateUserImage(data) {
  return request({
    url: api.update_profile_img,
    method: "patch",
    data: data,
  });
}

// ***************************************************  update user image  ****************************************************************
export async function UploadDocuments(data) {
  return request({ url: api.upload_documents, method: "patch", data: data });
}

// ***************************************************  Change Password  ****************************************************************
export async function ChangePassword(data) {
  return request({ url: api.change_password, method: "post", data: data });
}

// ***************************************************  forgot password  ****************************************************************
export async function ResetPassword(data) {
  return request({ url: api.reset_password, method: "post", data: data });
}

// ***************************************************  get category list  ****************************************************************
export async function GetCategoryList() {
  return request({ url: api.categoriesList, method: "get" });
}

// ***************************************************  get products by category  ****************************************************************
export async function GetProductsByCategory(id) {
  return request({ url: api.productsByCategory + "/" + id, method: "get" });
}

// ***************************************************  get product details bu id  ****************************************************************
export async function GetProductDetailsById(id) {
  return request({ url: api.productDetails + "/" + id, method: "get" });
}

// ***************************************************  get session questions  ****************************************************************
export async function GetSessionQuestions(data) {
  return request({ url: api.sessionQuestions, method: "post", data: data });
}

// ***************************************************  get cart questions  ****************************************************************
export async function GetCartQuestions(id) {
  return request({ url: api.cartQuestions + "/" + id, method: "get" });
}

// ***************************************************  get all products  ****************************************************************
export async function GetAllProducts() {
  return request({ url: api.allProducts, method: "get" });
}

// ***************************************************  get product details bu id  ****************************************************************
export async function GetCaseStudyById(slug) {
  return request({ url: api.caseStudy + "/" + slug, method: "get" });
}

// ***************************************************  get product details bu id  ****************************************************************
export async function GetAllCaseStudies() {
  return request({ url: api.caseStudy, method: "get" });
}

// ***************************************************  submit inquiry details  ****************************************************************
export async function PostInquiryDetails(data) {
  return request({ url: api.inquiry, method: "post", data: data });
}

// ***************************************************  submit inquiry media  ****************************************************************
// export async function PostInquiryMedia(id, data) {
//     return request({
//         url: api.inquiryMedia + "/" + id,
//         method: "post",
//         data: data,
//     });
// }

// ***************************************************  get inquiry details  ****************************************************************
export async function GetInquiryDetails() {
  return request({ url: api.inquiry, method: "get" });
}

// ***************************************************  get cart details  ****************************************************************
export async function GetCart() {
  return request({ url: api.cart, method: "get" });
}

// ***************************************************  add item to cart  ****************************************************************
export async function AddItemToCart(data) {
  return request({ url: api.cart, method: "post", data: data });
}

// ***************************************************  remove item to cart  ****************************************************************
export async function RemoveItemFromCart(id) {
  return request({ url: api.cart + "/" + id, method: "delete" });
}

// ***************************************************  check coupon code available  ****************************************************************
export async function CheckCouponCodeAvailable(code, cart_value) {
  return request({
    url:
      api.coupon_code_available +
      "?coupon_code=" +
      code +
      "&cart_value=" +
      cart_value,
    method: "get",
  });
}

// ***************************************************  initiate payment  ****************************************************************
export async function InitiatePayment(data) {
  return request({ url: api.payment, method: "post", data: data });
}

// ***************************************************  initiate payment  ****************************************************************
export async function CompletePayment(data) {
  return request({ url: api.complete_payment, method: "post", data: data });
}

// ***************************************************  get active services  ****************************************************************
export async function GetActiveServices() {
  return request({ url: api.active_services, method: "get" });
}

// ***************************************************  get all activated services  ****************************************************************
export async function GetAllActiveServices() {
  return request({ url: api.all_activated_services, method: "get" });
}

// ***************************************************  get support tickets  ****************************************************************
export async function GetSupportTickets() {
  return request({ url: api.support_ticket, method: "get" });
}

// ***************************************************  create support ticket ****************************************************************
export async function CreateSupportTickets(data) {
  return request({ url: api.support_ticket, method: "post", data: data });
}

// ***************************************************  create support ticket ****************************************************************
export async function SubmitContactRequest(data) {
  return request({ url: api.contact, method: "post", data: data });
}

// ***************************************************  get home content ****************************************************************
export async function GetHomeContent() {
  return request({ url: api.home, method: "get" });
}

// ***************************************************  get about content ****************************************************************
export async function GetAboutContent() {
  return request({ url: api.about, method: "get" });
}

// ***************************************************  get client details ****************************************************************
export async function GetClientDetails() {
  return request({ url: api.clients, method: "get" });
}

// ***************************************************  get client details ****************************************************************
export async function GetClientReviews() {
  return request({ url: api.clientReviews, method: "get" });
}

// ***************************************************  get client details ****************************************************************
export async function SendOtp(data) {
  return request({ url: api.otp, method: "post", data: data });
}

// ***************************************************  get client details ****************************************************************
export async function VerifyOtp(data) {
  return request({ url: api.verify_otp, method: "post", data: data });
}

// ***************************************************  submit basic details  ****************************************************************
export async function PostBasicDetailsDetails(data) {
  // console.log(data)
  return request({ url: api.basic_details, method: "post", data: data });
}

// ***************************************************  get terms of use ****************************************************************
export async function GetTermsOfUse() {
  return request({ url: api.terms, method: "get" });
}

// ***************************************************  get terms of use ****************************************************************
export async function GetPrivacyPolicy() {
  return request({ url: api.privacy, method: "get" });
}

// ***************************************************  get terms of use ****************************************************************
export async function GetRefundPolicy() {
  return request({ url: api.refund, method: "get" });
}

// *************************************************** create new chat ****************************************************************
export async function CreateChat() {
  return request({ url: api.create_chat, method: "post" });
}

// *************************************************** get chats ****************************************************************
export async function GetChats() {
  return request({ url: api.create_chat, method: "get" });
}

// *************************************************** change chat name ****************************************************************
export async function ChangeChatName(id, name) {
  return request({
    url: api.create_chat + "/" + id + "/" + name,
    method: "patch",
  });
}

// *************************************************** delete chat ****************************************************************
export async function DeleteChat(id) {
  return request({ url: api.create_chat + "/" + id, method: "delete" });
}

// *************************************************** change response type ****************************************************************
export async function ChangeResponseStyle(id, responseStyle) {
  return request({
    url: api.create_chat + "/response-style/" + id + "/" + responseStyle,
    method: "patch",
  });
}

// *************************************************** getMessageHistory ****************************************************************
export async function GetMessageHistory(id) {
  return request({ url: api.create_chat + "/" + id, method: "get" });
}

// *************************************************** update like status ****************************************************************
export async function UpdateLikeStatus(id, status) {
  return request({
    url: api.like_status + "/" + id + "/" + status,
    method: "patch",
  });
}

// *************************************************** change chat name ****************************************************************
export async function test_stream() {
  return request({
    url: "http://localhost:8000/api/chat/streaming",
    method: "get",
  });
}

// *************************************************** generate chat name ****************************************************************
export async function GenerateChatName(id) {
  return request({ url: api.generate_chat_name + "/" + id, method: "get" });
}

// *************************************************** generate refresh token ****************************************************************
export async function GenerateRefreshToken() {
  return request({ url: api.generate_refresh_token, method: "get" });
}
// *************************************************** get blog detail ****************************************************************
export async function getBlogs(category,author,search) {
  return request({ url: `${api.getBlogs}?category=${category}&author=${author}&search=${search}`, method: "get" });
}
// *************************************************** get blog detail ****************************************************************
export async function getBlogDetail(slug) {
  return request({ url: `${api.getBlogDetail}/${slug}`, method: "get" });
}
// *************************************************** get author detail ****************************************************************
export async function getBlogAuthorDetail(slug) {
  return request({ url: `${api.getAuthorDetail}/${slug}`, method: "get" });
}
