import React, { useEffect, useState } from "react";

import "./Feedback.scss";
import { ReactComponent as Email } from "../../Assets/icons/mail.svg";
import { ReactComponent as Location } from "../../Assets/icons/location.svg";
import { ReactComponent as Call } from "../../Assets/icons/call-filled.svg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FeedbackData, GenerateRefreshToken } from "../../apiCall";
import { toast } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useStateValue } from "../../StateProvider";
import { Helmet } from "react-helmet";

const Feedback = () => {
  const [formData, setFormData] = useState({});
  const [, dispatch] = useStateValue();
  const canonicalUrl = "https://aapkacfo.com/feedback";

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue: setFormValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm();

  const submitForm = async (data) => {
    if (data.phoneNumber === "") {
      data.phoneNumber = 0;
    }
    const Re = await FeedbackData(data);
    // console.log(Re?.response?.status);
    if (Re?.response?.status === 401) {
      localStorage.clear();
      dispatch({
        type: "SET_USER_LOGGED_IN",
        status: false,
      });
      sessionStorage.setItem("last_location", "");
      toast.error("Token Expired.");
      navigate("/home");
    } else {
      if (Re.data?.status[0].Error === "False") {
        toast.success(
          "We appreciate your honesty and thank you for helping us serve you better!"
        );
        setFormData({});
        reset();
        window.scrollTo(0, 0);
      } else {
        if (Re.data?.status) {
          toast.error(Re.data.status[0].ResponseMessage);
        } else {
          if (
            Re?.response?.data?.detail ===
            "Unauthenticated User, Please login first."
        ) {
            const refreshRe = await GenerateRefreshToken();

            console.log(refreshRe, "REFRESH RE");

            if (refreshRe?.data?.access_token) {
                localStorage.setItem(
                    "refresh_token",
                    refreshRe?.data?.refresh_token
                );
                localStorage.setItem(
                    "token",
                    refreshRe?.data?.access_token
                );
                submitForm();
            }else{
                localStorage.clear()
                dispatch({
                  type: "SET_USER_LOGGED_IN",
                  status: false,
                });
            }

        } else {
            toast.error("Something went wrong, Please try agian.");
        }
        }
      }
    }
  };
  const setValue = (a, b) => {
    setFormValue(a, b);
    setFormData(getValues());
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="feedback-main">
       <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className="heading">
        <h1>
          Your Feedback <span>Matters!</span>
        </h1>
        <p>
          <ul>
            <li>
              Please take a few moments to provide your feedback
              <strong> anonymously</strong>.
            </li>
            <li>
              We value your opinion and are committed to continuously improving
              our services.
            </li>
            <li>
              Your responses will help us better understand your experience and
              make any necessary improvements to enhance our partnership.
            </li>
          </ul>
        </p>
        <p>
          <span>Rest assured that your feedback will remain completely</span>
          <strong>
            {" "}
            <br />
            <span> confidential and anonymous. </span>
          </strong>
          <br />
        </p>
      </div>
      <div className="content">
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="inputs">
            <div className="input-grp">
              <label>
                Overall Satisfaction <span>*</span>
              </label>
              <div className="emoji-container">
                <span
                  className={`emoji ${
                    formData.overallSatisfaction === "Very Satisfied"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    setValue("overallSatisfaction", "Very Satisfied")
                  }
                >
                  <TooltipCom tootlTipText="Very Satisfied" content="😄" />
                </span>
                <span
                  className={`emoji ${
                    formData.overallSatisfaction === "Satisfied" ? "active" : ""
                  }`}
                  onClick={() => setValue("overallSatisfaction", "Satisfied")}
                >
                  <TooltipCom tootlTipText="Satisfied" content="😊" />
                </span>
                <span
                  className={`emoji ${
                    formData.overallSatisfaction === "Neutral" ? "active" : ""
                  }`}
                  onClick={() => setValue("overallSatisfaction", "Neutral")}
                >
                  <TooltipCom tootlTipText="Neutral" content="😐" />
                </span>
                <span
                  className={`emoji ${
                    formData.overallSatisfaction === "Dissatisfied"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    setValue("overallSatisfaction", "Dissatisfied")
                  }
                >
                  <TooltipCom tootlTipText="Dissatisfied" content="😕" />
                </span>
                <span
                  className={`emoji ${
                    formData.overallSatisfaction === "Very Dissatisfied"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    setValue("overallSatisfaction", "Very Dissatisfied")
                  }
                >
                  <TooltipCom tootlTipText="Very Dissatisfied" content="😡" />
                </span>
              </div>
              <input
                type="text"
                style={{ display: "none" }}
                {...register("overallSatisfaction", {
                  required: true,
                })}
              />
              {errors.overallSatisfaction?.type === "required" && (
                <p className="form-error">Please select any.</p>
              )}
            </div>
            <div className="input-grp">
              <label>
                Communication Effectiveness <span>*</span>
              </label>
              <div className="emoji-container">
                <span
                  className={`emoji ${
                    formData.communicationEffectiveness === "Excellent"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    setValue("communicationEffectiveness", "Excellent")
                  }
                >
                  <TooltipCom tootlTipText="Excellent" content="😄" />
                </span>
                <span
                  className={`emoji ${
                    formData.communicationEffectiveness === "Good"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => setValue("communicationEffectiveness", "Good")}
                >
                  <TooltipCom tootlTipText="Good" content="😊" />
                </span>
                <span
                  className={`emoji ${
                    formData.communicationEffectiveness === "Fair"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => setValue("communicationEffectiveness", "Fair")}
                >
                  <TooltipCom tootlTipText="Fair" content="😐" />
                </span>
                <span
                  className={`emoji ${
                    formData.communicationEffectiveness === "Poor"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => setValue("communicationEffectiveness", "Poor")}
                >
                  <TooltipCom tootlTipText="Poor" content="😕" />
                </span>
                <span
                  className={`emoji ${
                    formData.communicationEffectiveness === "Very Poor"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    setValue("communicationEffectiveness", "Very Poor")
                  }
                >
                  <TooltipCom tootlTipText="Very Poor" content="😡" />
                </span>
              </div>
              <input
                type="text"
                style={{ display: "none" }}
                {...register("communicationEffectiveness", {
                  required: true,
                })}
              />
              {errors.communicationEffectiveness?.type === "required" && (
                <p className="form-error">Please select any.</p>
              )}
            </div>
            <div className="input-grp">
              <label>
                Service Quality <span>*</span>
              </label>
              <div className="emoji-container">
                <span
                  className={`emoji ${
                    formData.serviceQuality === "Excellent" ? "active" : ""
                  }`}
                  onClick={() => setValue("serviceQuality", "Excellent")}
                >
                  <TooltipCom tootlTipText="Excellent" content="😄" />
                </span>
                <span
                  className={`emoji ${
                    formData.serviceQuality === "Good" ? "active" : ""
                  }`}
                  onClick={() => setValue("serviceQuality", "Good")}
                >
                  <TooltipCom tootlTipText="Good" content="😊" />
                </span>
                <span
                  className={`emoji ${
                    formData.serviceQuality === "Fair" ? "active" : ""
                  }`}
                  onClick={() => setValue("serviceQuality", "Fair")}
                >
                  <TooltipCom tootlTipText="Fair" content="😐" />
                </span>
                <span
                  className={`emoji ${
                    formData.serviceQuality === "Poor" ? "active" : ""
                  }`}
                  onClick={() => setValue("serviceQuality", "Poor")}
                >
                  <TooltipCom tootlTipText="Poor" content="😕" />
                </span>
                <span
                  className={`emoji ${
                    formData.serviceQuality === "Very Poor" ? "active" : ""
                  }`}
                  onClick={() => setValue("serviceQuality", "Very Poor")}
                >
                  <TooltipCom tootlTipText="Very Poor" content="😡" />
                </span>
              </div>
              <input
                type="text"
                style={{ display: "none" }}
                {...register("serviceQuality", {
                  required: true,
                })}
              />

              {errors.serviceQuality?.type === "required" && (
                <p className="form-error">Please select any.</p>
              )}
            </div>
            <div className="input-grp">
              <label>
                Value for Money <span>*</span>
              </label>
              <div className="emoji-container">
                <span
                  className={`emoji ${
                    formData.valueForMoney === "Strongly Agree" ? "active" : ""
                  }`}
                  onClick={() => setValue("valueForMoney", "Strongly Agree")}
                >
                  <TooltipCom tootlTipText="Strongly Agree" content="😄" />
                </span>
                <span
                  className={`emoji ${
                    formData.valueForMoney === "Agree" ? "active" : ""
                  }`}
                  onClick={() => setValue("valueForMoney", "Agree")}
                >
                  <TooltipCom tootlTipText="Agree" content="😊" />
                </span>
                <span
                  className={`emoji ${
                    formData.valueForMoney === "Neutral" ? "active" : ""
                  }`}
                  onClick={() => setValue("valueForMoney", "Neutral")}
                >
                  <TooltipCom tootlTipText="Neutral" content="😐" />
                </span>
                <span
                  className={`emoji ${
                    formData.valueForMoney === "Disagree" ? "active" : ""
                  }`}
                  onClick={() => setValue("valueForMoney", "Disagree")}
                >
                  <TooltipCom tootlTipText="Disagree" content="😕" />
                </span>
                <span
                  className={`emoji ${
                    formData.valueForMoney === "Strongly Disagree"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => setValue("valueForMoney", "Strongly Disagree")}
                >
                  <TooltipCom tootlTipText="Strongly Disagree" content="😡" />
                </span>
              </div>
              <input
                type="hidden"
                style={{ display: "none" }}
                {...register("valueForMoney", {
                  required: true,
                })}
              />

              {errors.valueForMoney?.type === "required" && (
                <p className="form-error">Please select any.</p>
              )}
            </div>
            <div className="input-grp">
              <label htmlFor="message">
                Areas for Improvement <span>*</span>
              </label>
              <textarea
                placeholder="Leave us a message"
                {...register("areasForImprovement", {
                  required: "Areas for Improvement required",
                })}
              ></textarea>
              {errors.areasForImprovement && (
                <p className="form-error">
                  {errors.areasForImprovement.message}
                </p>
              )}
            </div>
            <div className="input-grp">
              <label htmlFor="message">
                Additional Comments <span>*</span>
              </label>
              <textarea
                placeholder="Leave us a message"
                {...register("comment", {
                  required: "Additional Comments required.",
                })}
              ></textarea>
              {errors.comment && (
                <p className="form-error">{errors.comment.message}</p>
              )}
            </div>
            <div className="contect-info">
              <h6>
                If you would like us to follow up with you regarding your
                feedback, please provide your contact information here.
              </h6>
              <div className="input-grp">
                <label htmlFor="email">Email (if desired)</label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  {...register("email", {
                    maxLength: {
                      value: 50,
                      message: "The length should be less than 50 characters.",
                    },
                  })}
                />
                {errors.email && (
                  <p className="form-error">{errors.email.message}</p>
                )}
              </div>
              <div className="input-grp">
                <label htmlFor="phone_number">Phone number (if desired)</label>
                <input
                  type="number"
                  placeholder="Enter your phone number"
                  {...register("phoneNumber", { pattern: /^[6-9]\d{9}$/ })}
                />
                {errors.phoneNumber?.type === "pattern" && (
                  <p className="form-error">Invalid Phone number.</p>
                )}
              </div>
            </div>
            <span className="bottom-content">
              {" "}
              We appreciate your honesty and thank you for helping us serve you
              better!
            </span>
            <button type="submit" className="primary">
              Send message
            </button>
          </div>
        </form>
      </div>
      <div className="feedback-info">
        <div className="section">
          <Email />
          <p className="bold title">Email</p>
          <a href="mailto:info@aapkacfo.com" target="__blank" className="text">
            info@aapkacfo.com
          </a>
        </div>
        <div className="section">
          <Location />
          <p className="bold title">Our Offices</p>
          <div className="office">
            <p className="bold">India</p>
            <p className="text">
              501 Solitaire 1, Opp Infinity Mall, Link Road Malad (West) Mumbai
              - 400064
            </p>
          </div>
          <div className="office">
            <p className="bold">UAE</p>
            <p className="text">
              112C, 138C Building No. 280, Taweelah, Abu Dhabi, United Arab
              Emirates
            </p>
          </div>
        </div>
        <div className="section">
          <Call />
          <p className="bold title">Phone</p>
          <a href="tel:+919321822974" target="__blank" className="text">
            +91 9321 822 974
          </a>
        </div>
      </div>
    </div>
  );
};

export default Feedback;

const TooltipCom = ({ tootlTipText, content }) => {
  return (
    <OverlayTrigger
      delay={{ hide: 100, show: 100 }}
      overlay={(props) => <Tooltip {...props}>{tootlTipText}</Tooltip>}
      placement="bottom"
    >
      <div className="button" variant="warning">
        {content}
      </div>
    </OverlayTrigger>
  );
};
