import React, { useEffect, useState } from "react";
import "./BlogDetail.scss";
import BgImage from "../../Assets/images/bg_blog.png";
import BgLeft from "../../Assets/images/vector_1.png";
import BgRight from "../../Assets/images/vector_2.png";
import { FaCalendar } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import blogImage from "../../Assets/images/dummy_blog_img.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getBlogDetail, SubmitContactRequest } from "../../apiCall";
import { toast } from "react-toastify";
import { CACHE_TIME } from "../../Constants";
import { formatDateTime } from "../../utils/utils";
import { useForm } from "react-hook-form";
import CountryCodes from "../../CountryCodes.json";
import Accordion from "react-bootstrap/Accordion";

export default function BlogDetail() {
  const { slug } = useParams();
  const [activeKey, setActiveKey] = useState("0");
  const navigateTo = useNavigate();

  const {
    isLoading: isBlogDetailLoding,
    data: blogData,
    isFetching,
  } = useQuery(["blog-detail", slug], () => getBlogDetail(slug), {
    // staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
    onSuccess: (data) => {
      if (data.data?.status[0].Error === "False") {
        setBlogDetail(data.data.value);
        return data.data.value;
      }
      return {};
    },
    onError: (err) => {
      // console.log(err, "ERROR");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong");
      }
    },
  });
  const [blogDetail, setBlogDetail] = useState(blogData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const submitForm = async (data) => {
    data.name = data.first_name + " " + data.last_name;
    const Re = await SubmitContactRequest(data);
    // console.log(Re);

    if (Re.data?.status[0].Error === "False") {
      toast.success(
        "We have received your query and will get back to you soon."
      );
      reset();
      navigateTo("/thank-you");
    } else {
      if (Re.data?.status) {
        toast.error(
          Re.data.status[0].Message
            ? Re.data.status[0].Message
            : Re.data.status[0].ResponseMessage
        );
      } else {
        toast.error("Something went wrong, Please try again.");
      }
    }
  };

  const handleScroll = (id, offset = 50) => {
    setActiveKey(0);
    const element = document.getElementById(id);
    // const offset = offset; // Margin in pixels
    const bodyRect = document.body.getBoundingClientRect().top; // Distance from top of the page
    const elementRect = element.getBoundingClientRect().top; // Distance from top of viewport
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };
  return (
    <div className="blog-detail">
      <div className="wrapper">
        <section className="section-banner">
          <img src={BgLeft} alt="" className="bg-left" />
          <img src={BgRight} alt="" className="bg-right" />
          <div className="content-wrapper">
            {isBlogDetailLoding || isFetching ? (
              <div className="component-loading main-text-loading-cmp"></div>
            ) : (
              <>
                <h1 className="title">{blogDetail?.title}</h1>
                <div className="author-detail">
                  <div className="name">
                    <img src={blogDetail?.author?.image} alt="" />
                    <p>{blogDetail.author.name}</p>
                  </div>
                  <div className="publish-detail">
                    <div className="date">
                      <FaCalendar size={22} />
                      <p>
                        {formatDateTime(blogDetail?.created_at).formattedDate}
                      </p>
                    </div>
                    <div className="time">
                      <FaClock size={22} />
                      <p>
                        {formatDateTime(blogDetail?.created_at).formattedTime}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>

        <section className="section-blog-detail ">
          <div className="content-wrapper">
            <div className="left">
              <div className="table-of-content table-web">
                <h5 className="heading">Table of Contents</h5>
                <div className="content">
                  <ul>
                    {isBlogDetailLoding || isFetching ? (
                      <>
                        <div className="component-loading main-text-loading-cmp"></div>
                        <div className="component-loading main-text-loading-cmp"></div>
                        <div className="component-loading main-text-loading-cmp"></div>
                        <div className="component-loading main-text-loading-cmp"></div>
                      </>
                    ) : (
                      <>
                        {blogDetail?.sections.map((item) => {
                          if (item.heading) {
                            return (
                              <li>
                                <div onClick={() => handleScroll(item.slug)}>
                                  {item.heading}
                                </div>
                              </li>
                            );
                          }
                        })}
                      </>
                    )}
                  </ul>
                </div>
              </div>
              <div className="table-of-content table-mobile">
                <Accordion defaultActiveKey="1" activeKey={activeKey}>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header
                      onClick={() => {
                        if (activeKey !== "1") {
                          setActiveKey("1");
                        } else {
                          setActiveKey("0");
                        }
                      }}
                    >
                      <h5 className="heading">Table of Contents</h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="content">
                        <ul>
                          {isBlogDetailLoding || isFetching ? (
                            <>
                              <div className="component-loading main-text-loading-cmp"></div>
                              <div className="component-loading main-text-loading-cmp"></div>
                              <div className="component-loading main-text-loading-cmp"></div>
                              <div className="component-loading main-text-loading-cmp"></div>
                            </>
                          ) : (
                            <>
                              {blogDetail?.sections.map((item) => {
                                if (item.heading) {
                                  return (
                                    <li>
                                      <div
                                        onClick={() =>
                                          handleScroll(item.slug, 200)
                                        }
                                      >
                                        {item.heading}
                                      </div>
                                    </li>
                                  );
                                }
                              })}
                            </>
                          )}
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="call-to-action">
                <h5 className="heading">Get in touch</h5>
                <div className="connect-form">
                  <form>
                    <div className="input-grp">
                      <div className="input">
                        <label>First Name</label>
                        <input
                          type="text"
                          name="first_name"
                          placeholder="First Name"
                          {...register("first_name", {
                            required: true,
                            maxLength: 35,
                          })}
                        />
                        {errors.first_name?.type === "required" && (
                          <p className="form-error">First name is required.</p>
                        )}
                        {errors.first_name?.type === "maxLength" && (
                          <p className="form-error">
                            Length of first name can not be more than 35.
                          </p>
                        )}
                      </div>
                      <div className="input">
                        <label>Last Name</label>

                        <input
                          type="text"
                          name="last_name"
                          placeholder="Last Name"
                          {...register("last_name", {
                            required: true,
                            maxLength: 35,
                          })}
                        />
                        {errors.last_name?.type === "required" && (
                          <p className="form-error">Last name is required.</p>
                        )}
                        {errors.last_name?.type === "maxLength" && (
                          <p className="form-error">
                            Length of last name can not be more than 35.
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="input-grp">
                      <div className="input">
                        <label>Email</label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          {...register("email", {
                            required: true,
                          })}
                        />
                        {errors.email?.type === "required" && (
                          <p className="form-error">Email is required.</p>
                        )}
                      </div>
                      <div className="call-grp">
                        <label>Contact No.</label>

                        <div className="input">
                          <select
                            name=""
                            id=""
                            defaultValue={"+91"}
                            {...register("country_code")}
                          >
                            {CountryCodes.sort().map((code) => (
                              <option
                                value={code.dial_code}
                              >{`${code.code} (${code.dial_code})`}</option>
                            ))}
                            {/* 
                                            <option value='+91'>+91</option>
                                            <option value='+92'>+92</option>
                                            <option value='+93'>+93</option>
                                            <option value='+94'>+94</option>
                                            <option value='+95'>+95</option> */}
                          </select>
                          <input
                            type="number"
                            name="phone_number"
                            placeholder="Phone Number"
                            {...register("phone_number", {
                              required: true,
                            })}
                          />
                        </div>
                        {errors.phone_number?.type === "required" && (
                          <p className="form-error">
                            Phone number is required.
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="input-grp">
                      <div className="input">
                        <label> Message</label>

                        <textarea
                          type="text"
                          placeholder="Message"
                          rows={3}
                          {...register("message", {
                            required: true,
                          })}
                        />
                        {errors.message?.type === "required" && (
                          <p className="form-error">
                            Message field can not be empty.
                          </p>
                        )}
                      </div>
                    </div>
                    <button onClick={handleSubmit(submitForm)}>Submit</button>
                  </form>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="detail-wrapper">
                {isBlogDetailLoding || isFetching ? (
                  <>
                    <div
                      className="component-loading main-text-loading-cmp"
                      style={{ height: "50px" }}
                    ></div>
                    <div
                      className="component-loading main-text-loading-cmp"
                      style={{ height: "150px" }}
                    ></div>
                    <div
                      className="component-loading main-text-loading-cmp"
                      style={{ height: "50px" }}
                    ></div>
                    <div
                      className="component-loading main-text-loading-cmp"
                      style={{ height: "150px" }}
                    ></div>
                  </>
                ) : (
                  <>
                    {blogDetail?.sections.map((section) => (
                      <div className="blog-section" id={section?.slug}>
                        {section?.heading && (
                          <h4 className="heading">{section?.heading}</h4>
                        )}
                        {section?.highlighted_content && (
                          <div className="highlighted-content">
                            {section?.highlighted_content}
                          </div>
                        )}
                        {section?.content && (
                          <div className="content">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: section.content,
                              }}
                            />

                            {/* {section.content} */}
                          </div>
                        )}
                        {section?.pointers && (
                          <div className="pointer-wrapper">
                            {section?.pointers.map((pointer, index) => (
                              <div className="pointer">
                                <div className="heading">
                                  {index + 1}. {pointer.title}
                                </div>
                                {pointer?.content && (
                                  <div className="content">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: pointer?.content,
                                      }}
                                    />
                                    {pointer?.examples && (
                                      <div className="example-container">
                                        <div className="heading">Example</div>
                                        <div className="content">
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: pointer?.examples,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
                {/* <div className="blog-section">
                  <p className="content">
                    Pay-per-click (PPC) advertising is one of the most effective
                    ways to promote your business online. But not all businesses
                    are the same, and PPC works better for some than others. In
                    this guide, we’ll explain what PPC means, who can benefit
                    most from it, and why it’s such a valuable tool. By the end,
                    you’ll know if PPC advertising is the right strategy to grow
                    your business.
                  </p>
                </div>
                <div className="blog-section" id="WhatDoesPPCMean?">
                  <h4 className="heading">What Does PPC Mean?</h4>
                  <div className="highlighted-content">
                    PPC stands for pay-per-click advertising. It’s a type of
                    online marketing where you pay only when someone clicks on
                    your ad. These ads appear on search engines like Google, as
                    well as social media platforms like Facebook, Instagram, and
                    LinkedIn.
                  </div>
                  <div className="content">
                    Here’s why it’s so effective:
                    <ul>
                      <li>
                        <span>You only pay for results: </span>
                        If nobody clicks, you don’t pay.
                      </li>
                      <li>
                        <span>Target the right audience: </span>
                        Ads are shown to people
                      </li>
                      <li>
                        <span>Track everything: </span>
                        You can measure every click, visit, and sale to see how
                        well your ads are working. searching for what you offer.
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="blog-section"
                  id="WhatTypesofBusinessesShouldUsePPC?"
                >
                  <h4 className="heading">
                    What Types of Businesses Should Use PPC?
                  </h4>

                  <div className="content">
                    PPC advertising works for many types of businesses. Let’s
                    look at who benefits the most.
                  </div>
                  <div className="pointer-wrapper">
                    <div className="pointer">
                      <div className="heading">1. E-Commerce Businesses</div>
                      <div className="content">
                        If you sell products online, PPC can help you reach
                        shoppers who are ready to buy. You can showcase specific
                        items like clothing, electronics, or beauty products to
                        people searching for them.
                        <div className="example-container">
                          <div className="heading">Example</div>
                          <div className="content">
                            An online store selling fitness gear could run ads
                            for “best yoga mats” or “buy dumbbells online” to
                            attract fitness enthusiasts.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blog-section" id="WrapUp">
                  <h4 className="heading">Wrap Up</h4>
                  <div className="content">
                    PPC advertising is a smart way to get more people to your
                    website, generate leads, and increase sales quickly. It
                    works for all kinds of businesses, like online stores, local
                    services, or startups, by showing your ads to the right
                    people at the right time. At Persistent ROI, we create PPC
                    campaigns that help businesses grow and get the best
                    results. Want to see how PPC can work for you? Contact us
                    today to get started!
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="author-detail-wrapper">
            <img src={BgLeft} alt="" className="bg-left" />
            <img src={BgRight} alt="" className="bg-right" />
            <div className="content-wrapper">
              {isBlogDetailLoding || isFetching ? (
                <div className="component-loading main-text-loading-cmp"></div>
              ) : (
                <>
                  <div className="profile-img">
                    <img src={blogDetail?.author?.image} alt="" />
                  </div>
                  <div className="author-detail">
                    <Link to={`/author/${blogDetail?.author?.slug}`}>
                      <h3 className="name">{blogDetail?.author?.name}</h3>
                    </Link>
                    <div className="detail">{blogDetail?.author.detail}</div>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
