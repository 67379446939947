import React from 'react'
import"./LodingBlogCard.scss"

export default function LodingBlogCard() {
  return (
    <div className="loading-blog-card">
        <div className="component-loading main-text-loading-cmp"></div>
    </div>
  )
}
