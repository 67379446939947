import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StateProvider } from "./StateProvider";
import reducer, { initialState } from "./reducer";
import { QueryClient, QueryClientProvider } from "react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleClinetId } from "./Constants";
import TagManager from "react-gtm-module";

const queryClient = new QueryClient();
const tagManagerArgs = {
  gtnId: "GTM-P6DT54ND",
};
// TagManager.initialize(tagManagerArgs);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <StateProvider initialState={initialState} reducer={reducer}>
      <GoogleOAuthProvider clientId={googleClinetId}>
        <App />
      </GoogleOAuthProvider>
    </StateProvider>
  </QueryClientProvider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
