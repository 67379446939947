export const FAQ = [
  {
    question: "What CFO services does Aapka CFO provide?",
    answer:
      "We offer a comprehensive range of CFO services, including strategic financial planning, cash flow optimization, budgeting and forecasting, risk management, financial reporting, and trade finance solutions like bank guarantees, letters of credit, and supply chain financing.",
  },
  {
    question: "Who can benefit from Aapka CFO's services?",
    answer:
      "Our services are designed to support small and medium-sized enterprises (SMEs) across various industries, helping them manage their finances effectively and drive growth.",
  },
  {
    question: "How does Aapka CFO help SMEs with trade finance?",
    answer:
      "We provide SMEs with access to critical trade finance solutions, including structured trade, letters of credit, and supply chain financing, enabling businesses to unlock capital, mitigate risks, and expand internationally.",
  },
  {
    question: "How can I get started with Aapka CFO?",
    answer:
      "Getting started is easy! Simply schedule a free consultation with us, and we will assess your business needs and recommend the right financial solutions for you.",
  },
  {
    question:
      "What makes Aapka CFO different from other financial advisory services?",
    answer:
      "We combine years of experience with cutting-edge technology, offering personalized solutions that are affordable, accessible, and reliable. Our focus is on empowering businesses to grow with strategic financial insights.",
  },
  {
    question: "Are Aapka CFO's services available internationally?",
    answer:
      "Yes, we have expanded globally, with services available in markets including India, UAE, and the USA, and continue to support businesses aiming for international growth.",
  },
];
export const FAQUSA = [
  {
    question:
      "What services does Aapka CFO offer for USA Business Registration?",
    answer:
      "Aapka CFO assists with the full spectrum of documentation and administrative tasks necessary for business registration in the U.S., ensuring compliance with all legal requirements.",
  },
  {
    question: "How can Aapka CFO help with my U.S. business expansion?",
    answer:
      "We provide strategic planning and logistical support to set up your business operations, including advice on business structure, compliance with U.S. laws, and establishing necessary corporate entities for a seamless market entry.",
  },
  {
    question: "What visa support does Aapka CFO provide?",
    answer:
      "Our services include helping you prepare and apply for B1/B2 Visas, which are essential for business travel in the U.S. We offer guidance to enhance your application's success rate, supporting your business's expansion needs.",
  },
  {
    question: "How does Aapka CFO prepare clients for U.S. interviews? ",
    answer: "We offer comprehensive preparation for interviews and business meetings, focusing on effective communication strategies to confidently present your business to U.S. partners and investors.",
  },
  {
    question: " What is the process for integrating into the U.S. startup ecosystem with Aapka CFO’s help?",
    answer: "Aapka CFO assists in networking with key U.S. business stakeholders and navigating the startup ecosystem, providing strategic introductions and guidance on leveraging entrepreneurial resources to grow your business.",
  },
  
];
