import React from "react";
import blogImage from "../../../Assets/images/dummy_blog_img.png";
import { FaCalendar } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import "./BlogCard.scss";
import { Link } from "react-router-dom";
import { formatDateTime } from "../../../utils/utils";


export default function ({ data }) {
  return (
    <div className="blog-card">
      <div className="left">
        <img src={data?.blog_image} alt="" />
      </div>
      <div className="right">
        <Link to={`/blog-detail/${data?.slug}`} className="title">
          {data.title}
        </Link>
        <div className="author-detail">
          <Link to={`/author/${data?.author.slug}`} className="name">
            {data?.author?.name}
          </Link>
          <div className="publish-detail">
            <div className="date">
              <FaCalendar />
              <span>{formatDateTime(data?.created_at).formattedDate}</span>
            </div>
            <div className="time">
              <FaClock />
              <span>{formatDateTime(data?.created_at).formattedTime}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
