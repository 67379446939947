// Install react-helmet if not installed
// npm install react-helmet

import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { metaTags } from "../metaTags";



const DynamicMetaTags = () => {
    const location = useLocation();
    const hostname = window.location.hostname;
    const currentMeta =
    Object.entries(metaTags).find(([key]) => new URL(key).pathname === location.pathname)?.[1] ||
    {};
    return (
      <Helmet>
        <title>{currentMeta.title || "Default Title"}</title>
        <meta
          name="description"
          content={currentMeta.description || "Default Description"}
        />
      </Helmet>
    );
  };
  

export default DynamicMetaTags;
