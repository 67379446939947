import React from "react";

import "./productTable.scss";
import { Link } from "react-router-dom";

const ProductTable = ({ products, type }) => {

  return (
    <div className="product-table">
      {products ? (
        products
          ?.sort((prod1, prod2) => prod1.id - prod2.id)
          .map((prod) => (
            <Link
              key={prod.id}
              className="product"
              style={{textDecoration:"none"}}
              to={
                type === "products-service"
                  ? `/products/${prod.title.replace(/\s+/g, "-").toLowerCase()}`
                  : `/${prod.title.replace(/\s+/g, "-").toLowerCase()}`
              }
            >
              <h2 className="bold">{prod.title}</h2>
              <p>{prod.description}</p>
            </Link>
          ))
      ) : (
        <>
          <div className="product component-loading"></div>
          <div className="product component-loading"></div>
          <div className="product component-loading"></div>
          <div className="product component-loading"></div>
          <div className="product component-loading"></div>
          <div className="product component-loading"></div>
        </>
      )}
    </div>
  );
};

export default ProductTable;
