import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowBlack } from "../../../Assets/icons/black-arrow.svg";
import { useQuery } from "react-query";
import { CACHE_TIME } from "../../../Constants";
import { toast } from "react-toastify";
import { GenerateRefreshToken, GetSessionQuestions, PostBasicDetailsDetails } from "../../../apiCall";
import { getUniqueQuestions } from "../../../utils/utils";

import "./questions.scss";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../StateProvider";

const Questions = ({ selectedProducts, selectedCategories, setStep }) => {
    const [sessionQuestions, setSessionQuestions] = useState([]);
    const [uniqueQuestions, setUniqueQuestions] = useState();
    const [sessionAnswers, setSessionAnswers] = useState({});
    // const [productError, setProductError] = useState("");

    const navigateTo = useNavigate()
    const [, dispatch] = useStateValue()

    useQuery(
        ["session-questions", selectedProducts],
        () => GetSessionQuestions(selectedProducts),
        {
            enabled: selectedProducts ? true : false,
            // staleTime: STALE_TIME,
            cacheTime: CACHE_TIME,
            onSuccess: (data) => {
                // console.log(data);
                if (data.data?.status[0].Error === "False") {
                    setSessionQuestions(data.data.value);
                    setUniqueQuestions(getUniqueQuestions(data.data.value));
                }
            },
            onError: (err) => {
                // console.log(err, "ERROR");
                if (err.message) {
                    toast.error(err.message);
                } else {
                    toast.error("Something went wrong");
                }
            },
        }
    );

    // const validateSessionAnswers = () => {
    //     let hasErrors = false;
    //     for (let j = 0; j < uniqueQuestions.length; j++) {
    //         const question = uniqueQuestions[j];
    //         const key = question.id;
    //         const answer = sessionAnswers[key];
    //         // console.log(
    //             question.id,
    //             answer,
    //             question.dependent_question,
    //             question.dependent_answer,
    //             sessionAnswers[question.dependent_question]
    //         );
    //         if (
    //             !question.dependent_question ||
    //             sessionAnswers[question.dependent_question] ===
    //                 question.dependent_answer
    //         ) {
    //             if (typeof answer === "object") {
    //                 if (answer.length) {
    //                     setSessionAnswersErrors((pre) => {
    //                         const new_errors = { ...pre };
    //                         new_errors[key] = false;
    //                         return new_errors;
    //                     });
    //                 } else {
    //                     // console.log(key);
    //                     setSessionAnswersErrors((pre) => {
    //                         const new_errors = { ...pre };
    //                         new_errors[key] = true;
    //                         return new_errors;
    //                     });
    //                     hasErrors = true;
    //                 }
    //             } else {
    //                 if (answer) {
    //                     setSessionAnswersErrors((pre) => {
    //                         const new_errors = { ...pre };
    //                         new_errors[key] = false;
    //                         return new_errors;
    //                     });
    //                 } else {
    //                     // console.log(key);
    //                     setSessionAnswersErrors((pre) => {
    //                         const new_errors = { ...pre };
    //                         new_errors[key] = true;
    //                         return new_errors;
    //                     });
    //                     hasErrors = true;
    //                 }
    //             }
    //         } else {
    //             setSessionAnswersErrors((pre) => {
    //                 const new_errors = { ...pre };
    //                 new_errors[key] = true;
    //                 return new_errors;
    //             });
    //         }
    //     }
    //     return hasErrors;
    // };

    const handleAnswerSubmit = async () => {
        const data = {}
        data.category = selectedCategories;
        data.products = selectedProducts;

        // const hasErrors = validateSessionAnswers();
        const hasErrors = false;
        // console.log(hasErrors);
        // console.log(sessionAnswersErrors);
        if (hasErrors) {
            return;
        }

        const session_answers = sessionQuestions?.map((product) => {
            const product_obj = {
                product_id: product.id,
                product_title: product.title,
                answers: product.session_questions.map((question) => {
                    const q_a_obj = {
                        question: question.question,
                        answer: sessionAnswers[question.id],
                    };
                    return q_a_obj;
                }),
            };
            return product_obj;
        });
        data.question_answers = session_answers ? session_answers : [];
        
        const basic_details = JSON.parse(sessionStorage.getItem("basic_details"))
        data.first_name = basic_details.first_name
        data.last_name = basic_details.last_name
        data.email = basic_details.email
        data.country_code = basic_details.country_code
        data.phone_number = basic_details.phone_number
        data.company_name = basic_details.company_name
        data.sector = basic_details.sector
        // console.log(data);
        const Re = await PostBasicDetailsDetails(data)
        // console.log(Re)

        if (
            Re?.data?.status[0].Error === "False"
        ) {
            if(sessionStorage.getItem('last_location')){
                navigateTo(sessionStorage.getItem('last_location'))
            }else{
                navigateTo('/home')
            }
        } else {
            if (Re?.data?.status[0].Error === "True") {
                toast.error(Re.data.status[0].ResponseMessage);
            } else if(Re?.response?.data?.status[0].Error === "True"){
                toast.error(Re.response.data.status[0].ResponseMessage);

            } else {
                if (
                    Re?.response?.data?.detail ===
                    "Unauthenticated User, Please login first."
                ) {
                    const refreshRe = await GenerateRefreshToken();

                    console.log(refreshRe, "REFRESH RE");

                    if (refreshRe?.data?.access_token) {
                        localStorage.setItem(
                            "refresh_token",
                            refreshRe?.data?.refresh_token
                        );
                        localStorage.setItem(
                            "token",
                            refreshRe?.data?.access_token
                        );
                        handleAnswerSubmit();
                    }else{
                        localStorage.clear()
                        dispatch({
                            type: "SET_USER_LOGGED_IN",
                            status: false,
                          });
                    }

                } else {
                    toast.error("Something went wrong, Please try agian.");
                }
            }
        }
    };

    useEffect(() => {
        if(uniqueQuestions?.length === 0){
            handleAnswerSubmit()
        }
    })
    return (
        <div className='questions'>
            <span
                className='arrow primary-btn'
                onClick={() => setStep((pre) => pre - 1)}>
                <ArrowBlack />
            </span>
            <h3>Thanks for selecting our products!</h3>
            <p>
                Can you give answers of some questions so we can assist you
                better.
            </p>
            <div className='session-questions-container'>
                {uniqueQuestions?.map(
                    (question) =>
                        (!question.dependent_question ||
                            sessionAnswers[question.dependent_question] ===
                                question.dependent_answer) && (
                            <div
                                className='input-grp session-questions'
                                key={"question" + question.id}>
                                <label htmlFor='notes'>
                                    {question.question}
                                </label>
                                {question.input_type === "MultiSelect" && (
                                    <p className='information'>
                                        Hold down the Ctrl (windows) or Command
                                        (Mac) button to select multiple options.
                                    </p>
                                )}
                                {question.input_type === "Text" && (
                                    <input
                                        type='text'
                                        name='note'
                                        placeholder='Type here...'
                                        value={
                                            sessionAnswers[question.id]
                                                ? sessionAnswers[question.id]
                                                : ""
                                        }
                                        onChange={(e) =>
                                            setSessionAnswers((pre) => {
                                                const new_answers = {
                                                    ...pre,
                                                };
                                                new_answers[question.id] =
                                                    e.target.value;
                                                return new_answers;
                                            })
                                        }
                                    />
                                )}
                                {question.input_type === "Dropdown" && (
                                    <select
                                        value={
                                            sessionAnswers[question.id]
                                                ? sessionAnswers[question.id]
                                                : ""
                                        }
                                        onChange={(e) =>
                                            setSessionAnswers((pre) => {
                                                const new_answers = {
                                                    ...pre,
                                                };
                                                new_answers[question.id] =
                                                    e.target.value;
                                                return new_answers;
                                            })
                                        }>
                                        <option value=''>Select Answer</option>
                                        {question.options.map((option) => (
                                            <option
                                                key={`option-${question.id}-${option.id}`}
                                                value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </select>
                                )}
                                {question.input_type === "MultiSelect" && (
                                    <select
                                        value={
                                            sessionAnswers[question.id]
                                                ? sessionAnswers[question.id]
                                                : []
                                        }
                                        onChange={(e) =>
                                            setSessionAnswers((pre) => {
                                                const new_answers = {
                                                    ...pre,
                                                };
                                                new_answers[question.id] =
                                                    Array.from(
                                                        e.target
                                                            .selectedOptions,
                                                        (option) => option.value
                                                    );
                                                return new_answers;
                                            })
                                        }
                                        multiple>
                                        {question.options.map((option) => (
                                            <option
                                                key={`multi-option-${question.id}-${option.id}`}
                                                value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </select>
                                )}
                                {/* {sessionAnswersErrors[question.id] && (
                                    <p className='form-error'>
                                        This field is required.
                                    </p>
                                )} */}
                            </div>
                        )
                )}
            </div>
            <button type='submit' className='primary-btn submit-btn' onClick={handleAnswerSubmit}>
                Submit
            </button>
            {/* <button
                type="submit"
                className='primary-btn'
                onClick={() => setStep((pre) => pre + 1)}>
                Continue
            </button> */}
        </div>
    );
};

export default Questions;
