import React, { useEffect, useState } from "react";
import BgImage from "../../Assets/images/bg_blog.png";
import BgLeft from "../../Assets/images/vector_1.png";
import BgRight from "../../Assets/images/vector_2.png";
import { IoMdSearch } from "react-icons/io";
import { CACHE_TIME } from "../../Constants";

import blogImage from "../../Assets/images/dummy_blog_img.png";

import "./AuthorDetail.scss";
import BlogCard from "../Blogs/components/BlogCard";
import LodingBlogCard from "../Blogs/components/LodingBlogCard";
import { useQuery } from "react-query";
import { getBlogAuthorDetail, getBlogs } from "../../apiCall";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const dummyData = [
  {
    title:
      "Which Businesses Need PPC Advertising? A Guide to Maximizing Benefits",
    author_name: "Keyur Acharya",
    publish_date: "31 December, 2024",
    publish_time: "11:30 PM",
  },
  {
    title:
      "Which Businesses Need PPC Advertising? A Guide to Maximizing Benefits",
    author_name: "Keyur Acharya",
    publish_date: "31 December, 2024",
    publish_time: "11:30 PM",
  },
  {
    title:
      "Which Businesses Need PPC Advertising? A Guide to Maximizing Benefits",
    author_name: "Keyur Acharya",
    publish_date: "31 December, 2024",
    publish_time: "11:30 PM",
  },
  {
    title:
      "Which Businesses Need PPC Advertising? A Guide to Maximizing Benefits",
    author_name: "Keyur Acharya",
    publish_date: "31 December, 2024",
    publish_time: "11:30 PM",
  },
  {
    title:
      "Which Businesses Need PPC Advertising? A Guide to Maximizing Benefits",
    author_name: "Keyur Acharya",
    publish_date: "31 December, 2024",
    publish_time: "11:30 PM",
  },
];
export default function AuthorDetail() {
  const { slug } = useParams();
  const [blogs, setBlogs] = useState([]);
  const [authorDetail, setAuthorDetail] = useState({});
  const { isLoading: isAuthorDetailLoading } = useQuery(
    ["author-detail"],
    () => getBlogAuthorDetail(slug),
    {
      // staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
      onSuccess: (data) => {
        if (data.data?.status[0].Error === "False") {
          setAuthorDetail(data.data.value);
        }
      },
      onError: (err) => {
        // console.log(err, "ERROR");
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("Something went wrong");
        }
      },
    }
  );

  const { isLoading: isBlogLoding } = useQuery(
    ["blogs", slug],
    () => getBlogs("", slug, ""),
    {
      // staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
      onSuccess: (data) => {
        if (data.data?.status[0].Error === "False") {
          setBlogs(data.data.value);
        }
      },
      onError: (err) => {
        // console.log(err, "ERROR");
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("Something went wrong");
        }
      },
    }
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="blog-list">
      <div className="wrapper">
        <section className="author-detail-wrapper">
          <img src={BgLeft} alt="" className="bg-left" />
          <img src={BgRight} alt="" className="bg-right" />
          <div className="content-wrapper">
            {isAuthorDetailLoading ? (
              <div className="component-loading main-text-loading-cmp"></div>
            ) : (
              <>
                <div className="profile-img">
                  <img src={authorDetail.profile_image} alt="" />
                </div>
                <div className="author-detail">
                  <h3 className="name">{authorDetail.name}</h3>
                  <div className="detail">{authorDetail.detail}</div>
                </div>
              </>
            )}
          </div>
        </section>

        <section className="section-blog-list ">
          {/* <div className="filter">
            <div className="select-wrapper">
              <select>
                <option value={""}>All Category</option>
                <option value={"value-1"}>value-1</option>
                <option value={"value-2"}>value-2</option>
                <option value={"value-3"}>value-3</option>
              </select>
            </div>
            <div className="input-wrapper">
              <input type="text" placeholder="Search here"></input>
              <div className="search-icon">
                <IoMdSearch size={24} fill="white" />
              </div>
            </div>
          </div> */}
          <div className="blogs">
            {isBlogLoding ? (
              <>
                <LodingBlogCard />
                <LodingBlogCard />
                <LodingBlogCard />
                <LodingBlogCard />
              </>
            ) : (
              <>
                {blogs.map((data, index) => (
                  <BlogCard data={data} key={`blog-${index}`} />
                ))}
              </>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}
