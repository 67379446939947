import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./ProtectedRoutes";

import Home from "./views/Home/Home";
import "./App.scss";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
// import Products from "./views/Products/Products";
import ProductsAndService from "./views/ProductsAndService/ProductsAndService";
import Products from "./views/ProductsAndService/Components/Products";
import ProductDetails from "./views/ProductDetails/ProductDetails";
import CaseStudyDetails from "./views/CaseStudyDetails/CaseStudyDetails";
import ScheduleCall from "./views/ScheduleCall/ScheduleCall";
import Cart from "./views/Cart/Cart";
import Dashboard from "./views/Dashboard/Dashboard";
import ContactUs from "./views/ContactUs/ContactUs";
import AboutUs from "./views/AboutUs/AboutUs";
import PrivacyPolicy from "./views/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./views/TermsOfUse/TermsOfUse";
import Orders from "./views/Orders/Orders";
import Welcome from "./views/Welcome/Welcome";
import RefundPolicy from "./views/RefundPolicy/RefundPolicy";
import Feedback from "./views/Feedback/Feedback";
import Chatbot from "./views/Chatbot/Chatbot";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BusinessExpansionUSA from "./views/BusinessExpansionUSA/BusinessExpansionUSA";
import ThankYou from "./views/ThankYou/ThankYou";
import Blogs from "./views/Blogs/Blogs";
import BlogDetail from "./views/BlogDetail/BlogDetail";
import AuthorDetail from "./views/AuthorDetail/AuthorDetail";
import DynamicMetaTags from "./utils/DynamicMetaTags";

function App() {
  

  return (
    <div className="App">
      <BrowserRouter>
       <DynamicMetaTags />
        <Routes>
          <Route
            path="/home"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <Home />
                  {/* <Footer /> */}
                </ProtectedRoute>
              </>
            }
          />
    
          <Route
            path="/about-us"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <AboutUs />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
      
          {/* <Route
            path="/usa-summit-2025"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <Chatbot />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          /> */}
          <Route
            path="/usa-business-registration"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <BusinessExpansionUSA />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
       
          <Route
            path="/products-and-service"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <ProductsAndService />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
       
          <Route
            path="/products/:title"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <Products />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
      
          <Route
            path="/schedule-call"
            element={
              // <ProtectedRoute>
                <ScheduleCall />
              // </ProtectedRoute>
            }
          />
    
          <Route
            path="/case-study/:slug"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <CaseStudyDetails />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
       
          <Route
            path="/cart"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <Cart />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
        
          <Route
            path="/orders"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <Orders />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
       
          <Route
            path="/contact-us"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <ContactUs />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
     
          <Route
            path="/feedback"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <Feedback />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
       
          <Route
            path="/dashboard"
            element={
              <>
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              </>
            }
          />
      
          <Route
            path="/privacy-policy"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <PrivacyPolicy />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
       
          <Route
            path="/refund-policy"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <RefundPolicy />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
      
          <Route
            path="/terms-of-use"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <TermsOfUse />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/thank-you"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <ThankYou />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/blogs"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <Blogs />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/blog-detail/:slug"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <BlogDetail />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/author/:slug"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <AuthorDetail />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
          <Route
            path="/thank-you"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <ThankYou />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
       
          <Route
            path="/welcome"
            element={
              <>
                <ProtectedRoute>
                  <Header showOptions={false} />
                  <Welcome />
                </ProtectedRoute>
              </>
            }
          />
       
          <Route
            path="/:title"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <ProductDetails />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
        
          <Route
            path="*"
            element={
              <>
                <ProtectedRoute>
                  {/* <Header /> */}
                  {/* Redirect to /home for any unknown route */}
                  {/* {navigate("/home")} */}
                  {/* <Footer /> */}
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
      </BrowserRouter>
      {window.screen.width < 750 ? (
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          theme="light"
        />
      ) : (
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          theme="light"
        />
      )}
    </div>
  );
}

export default App;
