import React, { useState, useEffect } from "react";
import Collapse from "react-bootstrap/Collapse";

// import {ReactComponent as ProductDetailsBanner} from "../../Assets/images/product-detail-banner.svg"
// import ProductDetailsBanner1 from "../../Assets/images/product-detail-banner.png";
import { ReactComponent as ArrowBlack } from "../../Assets/icons/black-arrow.svg";
import { CgMathPlus, CgMathMinus } from "react-icons/cg";
// import CheckedRadio from "../../Assets/icons/radio-checked-outline.svg";
import "./productDetails.scss";
import ProductTable from "../ProductsAndService/Components/ProductTable";
// import CaseStudyImg from "../../Assets/images/case-study.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";

import { useNavigate, useParams } from "react-router-dom";
import {
  AddToCartModal,
  CartQuestionsModal,
  DocumentsModal,
} from "../../Modals";
import { useStateValue } from "../../StateProvider";
import { GetClientDetails, GetProductDetailsById } from "../../apiCall";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { CACHE_TIME, domainName } from "../../Constants";
import { Helmet } from "react-helmet";

// const CaseStudies = [
//     {
//         id: 1,
//         title: "Revolutionizing Retail 1",
//         about: "How Sam Tech Solutions Transformed E-commerce with Innovative AI Technology",
//         img: CaseStudyImg,
//     },
//     {
//         id: 2,
//         title: "Revolutionizing Retail 2",
//         about: "How Sam Tech Solutions Transformed E-commerce with Innovative AI Technology",
//         img: CaseStudyImg,
//     },
// ];
const ProductDetails = () => {
  const { title } = useParams();
  const [id, setId] = useState();
  const navigateTo = useNavigate();
  const canonicalUrl = `https://aapkacfo.com/${title}`;


  let slidesPerView = 10;
  if (window.innerWidth <= 350) {
    slidesPerView = 3;
  } else if (window.innerWidth <= 450) {
    slidesPerView = 3.5;
  } else if (window.innerWidth <= 550) {
    slidesPerView = 4;
  } else if (window.innerWidth <= 650) {
    slidesPerView = 5;
  } else if (window.innerWidth <= 750) {
    slidesPerView = 6;
  } else if (window.innerWidth <= 850) {
    slidesPerView = 7;
  } else if (window.innerWidth <= 950) {
    slidesPerView = 8;
  } else if (window.innerWidth <= 1050) {
    slidesPerView = 9;
  }

  const [currentCollaps, setCurrentCollaps] = useState(0);

  const handleCollapsChange = (num) => {
    if (currentCollaps === num) {
      setCurrentCollaps(0);
    } else {
      setCurrentCollaps(num);
    }
  };

  // For ui control
  // const [activeSlide, setActiveSlide] = useState(0);
  // const containerRef = useRef();
  // const [itemsPerScreen, setItemsPerScreen] = useState(3);
  // const caseStudyRef = useRef();
  const [{ isLoggedIn }, dispatch] = useStateValue();

  // For data control
  const [productDetails, setProductDetails] = useState();
  const [clientDetails, setClientDetails] = useState();

  // const getPaginationItems = () => {
  //     if (!productDetails) {
  //         return "";
  //     }
  //     const array = new Array(
  //         Math.ceil(productDetails.case_studies?.length / itemsPerScreen)
  //     ).fill(0);
  //     const items = array.map((item, indx) => (
  //         <span
  //             key={indx}
  //             onClick={() => setActiveSlide(indx)}
  //             className={`pagination-item ${
  //                 activeSlide === indx ? "activated" : ""
  //             }`}></span>
  //     ));
  //     return items;
  // };

  const setCartQuestionsModalShow = (status) => {
    dispatch({
      type: "SET_CART_QUESTIONS_MODAL_SHOW",
      status: status,
    });
  };

  const handleScheduleACall = () => {
    if (isLoggedIn) {
      navigateTo(
        `/schedule-call?serviceId=${productDetails.category_id}&productId=${title}`
      );
    } else {
      dispatch({
        type: "SET_LOGIN_MODAL_SHOW",
        status: true,
      });
    }
  };
  const handleAddToCart = () => {
    if (isLoggedIn) {
      setCartQuestionsModalShow(true);
    } else {
      dispatch({
        type: "SET_LOGIN_MODAL_SHOW",
        status: true,
      });
    }
  };

  // useEffect(() => {
  //     const handleScroll = () => {
  //         containerRef.current.scrollLeft =
  //             itemsPerScreen *
  //                 activeSlide *
  //                 (caseStudyRef.current?.offsetWidth
  //                     ? caseStudyRef.current?.offsetWidth
  //                     : 1) +
  //             itemsPerScreen * activeSlide * 25;
  //     };
  //     handleScroll();
  // }, [activeSlide, itemsPerScreen]);

  const { isLoading: product_details_loading } = useQuery(
    ["product-details", title],
    () => GetProductDetailsById(title),
    {
      // staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
      onSuccess: (data) => {
        if (data.data?.status[0].Error === "False") {
          setProductDetails(data.data.value);
          setId(data.data.value.id);
        }
      },
      onError: (err) => {
        // console.log(err, "ERROR");
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("Something went wrong");
        }
      },
    }
  );

  useQuery(["get-client"], () => GetClientDetails(), {
    // staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
    onSuccess: (data) => {
      if (data?.data?.status[0].Error === "False") {
        setClientDetails(data.data.value);
      }
    },
    onError: (err) => {
      // console.log(err, "ERROR");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong");
      }
    },
  });

  useEffect(() => {
    // if (window.innerWidth <= 450) {
    //     setItemsPerScreen(1);
    // } else if (window.innerWidth <= 700) {
    //     setItemsPerScreen(2);
    // }
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <div className="product-details-main">
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className="section-1">
        <div className="text">
          {!product_details_loading && (
            <>
              <h1>{productDetails?.title}</h1>
              <p>{productDetails?.description}</p>
            </>
          )}
          {/* {productDetails.statistics && (
                                <div className='statistics'>
                                    {productDetails.statistics.map(
                                        (stat, indx) => (
                                            <span
                                                className='item'
                                                key={"stat" + indx}>
                                                <h2>{stat.number}+</h2>
                                                <p>{stat.title}</p>
                                            </span>
                                        )
                                    )}
                                </div>
                            )} */}
        </div>
      </div>
      <div className="section-2">
        {productDetails && (
          <p className="price">
            <span className="arrow primary-btn" onClick={() => navigateTo(-1)}>
              <ArrowBlack />
            </span>
            {productDetails?.has_price_range
              ? "Starting From ₹" +
                productDetails.starting_price_range.toLocaleString() +
                " to ₹" +
                (productDetails?.ending_price_range
                  ? productDetails.ending_price_range.toLocaleString()
                  : "")
              : "₹" + productDetails?.price.toLocaleString()}{" "}
            {productDetails?.duration ? productDetails.duration : ""}{" "}
            <span className="astric">*</span>
          </p>
        )}
        {/* {productDetails.eligibility_criteria && (
                            <div className='eligibility'>
                                {productDetails.eligibility_criteria && (
                                    <>
                                        <p className='title'>
                                            Eligibility Criteria
                                        </p>
                                        <p className='description'>
                                            "Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit, sed do
                                        </p>
                                    </>
                                )}
                            </div>
                        )} */}
        {product_details_loading ? (
          <div className="component-loading my-list-loading"></div>
        ) : (
          productDetails?.eligibility_criteria?.length > 0 && (
            <div className="my-list">
              <p className="title">Eligibility Criteria</p>
              {/* {productDetails?.eligibility_criteria?.map(
                                (doc, indx) => (
                                    <span
                                        className='point'
                                        key={"eligibility_criteria" + indx}>
                                        <img src={CheckedRadio} alt='' />
                                        <p>{doc}</p>
                                    </span>
                                )
                            )} */}
              <ul>
                {productDetails?.eligibility_criteria?.map((doc) => (
                  <li>{doc}</li>
                ))}
              </ul>
            </div>
          )
        )}

        {product_details_loading ? (
          <div className="component-loading my-list-loading"></div>
        ) : (
          productDetails?.required_documents?.length > 0 && (
            <div className="my-list">
              <p className="title">Required Document</p>
              {/* {productDetails?.required_documents?.map(
                                (doc, indx) => (
                                    <span
                                        className='point'
                                        key={"required_doc" + indx}>
                                        <img src={CheckedRadio} alt='' />
                                        <p>{doc}</p>
                                    </span>
                                )
                            )} */}
              <ul>
                {productDetails?.required_documents?.map((doc) => (
                  <li>{doc}</li>
                ))}
              </ul>
            </div>
          )
        )}
        {productDetails?.deliverables?.length > 0 && (
          <div className="my-list">
            <p className="title">Deliverables</p>
            {/* {productDetails?.deliverables?.map((doc, indx) => (
                            <span className='point' key={"required_doc" + indx}>
                                <img src={CheckedRadio} alt='' />
                                <p>{doc}</p>
                            </span>
                        ))} */}
            <ul>
              {productDetails?.deliverables?.map((doc) => (
                <li>{doc}</li>
              ))}
            </ul>
          </div>
        )}
        {/* <div className='my-list'>
                            {productDetails?.required_documents?.map(
                                (doc, indx) => (
                                    <span
                                        className='point'
                                        key={"required_doc" + indx}>
                                        <img src={CheckedRadio} alt='' />
                                        <p>{doc}</p>
                                    </span>
                                )
                            )}
                        </div> */}
        <div className="buttons">
          {product_details_loading ? (
            <div className="component-loading loading-btn"></div>
          ) : (
            <button className="primary" onClick={() => handleScheduleACall()}>
              Schedule call with us
            </button>
          )}
          {product_details_loading ? (
            <div className="component-loading loading-btn"></div>
          ) : productDetails?.is_technology_product ? (
            <></>
          ) : (
            <button className="secondary" onClick={handleAddToCart}>
              Add to cart
            </button>
          )}
        </div>
        {productDetails?.note && (
          <div className="note">
            <p className="title">Note</p>
            <p className="description">{productDetails?.note}</p>
          </div>
        )}
        {productDetails?.faqs && (
          <div className="faqs">
            {productDetails?.faqs.map((faq, indx) => (
              <div className="fc-light fs-p" key={"faq" + indx}>
                <p
                  className="each-faq"
                  onClick={() => handleCollapsChange(indx + 1)}
                >
                  {faq.question}
                  <span
                    className="plus-faq"
                    aria-controls={"example-collapse-text" + indx + 1}
                    aria-expanded={currentCollaps === indx + 1}
                  >
                    {currentCollaps === indx + 1 ? (
                      <CgMathMinus />
                    ) : (
                      <CgMathPlus />
                    )}
                  </span>
                </p>

                <Collapse in={currentCollaps === indx + 1}>
                  <div
                    className="each-faq-ans"
                    id={"example-collapse-text" + indx + 1}
                  >
                    {faq.answer}
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        )}

        <div className="recommendation-services">
          {productDetails?.recommended_products?.length > 0 && (
            <>
              <h1>Recommendation Services</h1>
              {/* <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Etiam luctus neque quis nisi
                                pharetra, eu vestibulum felis lacinia. Cras
                                sollicitudin faucibus est nec porttitor.
                            </p> */}
            </>
          )}
          <ProductTable
            products={productDetails?.recommended_products}
            type={"products"}
          />
        </div>
      </div>
      {/* <div className='section-3'>
                <div className='section-container'>
                    <h1>Our Case Studies</h1>
                    <p className='description'>
                        Showcasing our success stories and testimonials from our
                        clients across various domains and industries
                    </p>
                    <div className='case-study-container' ref={containerRef}>
                        {productDetails?.case_studies?.map((study, indx) => (
                            <div
                                key={study.id}
                                className='case-study'
                                ref={indx === 0 ? caseStudyRef : null}>
                                <img
                                    src={domainName + "/uploads/" + study.image}
                                    alt=''
                                />
                                <p className='title bold'>{study.title}</p>
                                <p className='about'>{study.tag_line}</p>
                                <p
                                    className='read'
                                    onClick={() => {
                                        navigateTo("/case-study/" + study.id);
                                    }}>
                                    Read More
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className='pagination'>{getPaginationItems()}</div>
                </div>
            </div> */}
      <div className="section-4">
        {clientDetails && (
          <div className="section-container">
            <p>Clients & companies we work with</p>
            <div className="client-container">
              <div className="client-logo-swiper">
                <Swiper
                  spaceBetween={24}
                  centeredSlides={true}
                  autoplay={{
                    delay: 500,
                    disableOnInteraction: false,
                  }}
                  loopedSlides={true}
                  loop={true}
                  modules={[Autoplay]}
                  className="mySwiper"
                  slidesPerView={slidesPerView}
                  // slidesPerGroup={3}
                  speed={500}
                >
                  {clientDetails?.map((client) => (
                    <SwiperSlide key={"client" + client.id}>
                      <div className="client">
                        <img
                          src={domainName + "/uploads/" + client.company_logo}
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        )}
      </div>
      {productDetails && (
        <>
          <AddToCartModal
            id={id}
            description={productDetails.description}
            product_img={productDetails.product_img}
            title={productDetails.title}
            price={productDetails.price}
          />
          <CartQuestionsModal />
          <DocumentsModal
            documents={productDetails.required_documents}
            id={id}
          />
        </>
      )}
    </div>
  );
};

export default ProductDetails;
