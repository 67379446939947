import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Email } from "../../Assets/icons/mail.svg";
import { ReactComponent as Call } from "../../Assets/icons/call.svg";
import { ReactComponent as Facebook } from "../../Assets/icons/facebook.svg";
import { ReactComponent as LinkedIn } from "../../Assets/icons/linkedIn.svg";
import { ReactComponent as Twitter } from "../../Assets/icons/twitter.svg";
import { ReactComponent as Logo } from "../../Assets/icons/logo-2.svg";
import { ReactComponent as Location } from "../../Assets/icons/location.svg";

// import { ReactComponent as WhatsApp } from "../../Assets/icons/whatsapp.svg";
import { ReactComponent as Instagram } from "../../Assets/icons/instagram.svg";
import "./footer.scss";

const Footer = () => {
  return (
    <footer className="footer-main">
      <div className="footer-section f-section-1">
        <div className="row-1">
          <div className="f-col-1">
            <div className="social-wrapper">
              <div className="logo">
                <Logo />
              </div>
              <span className="social">
                <div className="logos">
                  {/* <span className='insta'>{<Insta />}</span> */}
                  <a
                    href="https://www.instagram.com/aapkacfo/"
                    className="instagram"
                    target="__blank"
                  >
                    {<Instagram width={28} height={28} />}
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=100062852317845"
                    className="facebook"
                    target="__blank"
                  >
                    {<Facebook width={28} height={28} />}
                  </a>
                  <a
                    href="https://www.linkedin.com/company/aapka-cfo/"
                    className="linkedin"
                    target="__blank"
                  >
                    {<LinkedIn width={28} height={28} />}
                  </a>
                  <a
                    href="https://twitter.com/AapkaCfo"
                    className="twitter"
                    target="__blank"
                  >
                    {<Twitter width={28} height={28} />}
                  </a>
                  {/* <a className='whatsapp'>{<WhatsApp />}</a> */}
                </div>
              </span>
            </div>
            {/* <div className="email-subscribe">
              <input
                type="text"
                placeholder="Stay Updated with the Latest Financial Insights"
              />
              <button>Submit</button>
            </div> */}
          </div>
          <div className="col-wrapper">
            <div className="f-col-2">
              <div className="about-items">
                <Link to={"/about-us"} className="about-link">
                  About Us
                </Link>
                <Link to={"/contact-us"} className="about-link">
                  Contact Us
                </Link>
                <Link to={"/products-and-service"} className="about-link">
                  Products
                </Link>
                <Link to={"/blogs"} className="about-link">
                  Blogs
                </Link>
                <Link to={"/privacy-policy"} className="about-link">
                  Privacy Policy
                </Link>
                <Link to={"/refund-policy"} className="about-link">
                  Refund Policy
                </Link>
                <Link to={"/terms-of-use"} className="about-link">
                  Terms of Use
                </Link>
              </div>
            </div>
            <div className="f-col-3">
              <div className="contact-us-wrapper">
                <h4>Contact Us</h4>
                <div className="item-wrapper">
                  <div className="item address-1">
                    <div className="icon">
                      {" "}
                      <Location width={20} height={20} />{" "}
                    </div>
                    <p>
                      112C, 138C Building No. 280, Taweelah, Abu Dhabi, United
                      Arab Emirates
                    </p>
                  </div>
                  <div className="item address-2">
                    <div className="icon">
                      {" "}
                      <Location width={20} height={20} />
                    </div>
                    <p>
                      501 Solitaire 1, Opp Infinity Mall, Link Road Malad (West)
                      Mumbai - 400064
                    </p>
                  </div>
                  <div className="item phone-number">
                    <div className="icon">
                      <Call width={20} height={20} />{" "}
                    </div>
                    <p>+91 93218 22974</p>
                  </div>
                  <div className="item email">
                    <div className="icon">
                      {" "}
                      <Email width={20} height={20} />{" "}
                    </div>
                    <p>info@aapkacfo.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-section f-section-2">
        <div className="copyright">
          Copyright 2024 | All rights reserved by Aapka CFO
        </div>
      </div>
    </footer>
  );
};

export default Footer;
