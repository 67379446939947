import React, { useEffect } from "react";
import "./ThankYou.scss";
import { ReactComponent as ThakYouIcon } from "../../Assets/icons/thank-you.svg";
import { Link } from "react-router-dom";

export default function ThankYou() {
   useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <section className="section-tank-you">
      <div className="container">
        <ThakYouIcon height={"80px"} width={"80px"} />
        <h2>Thank you for choosing.</h2>
        <p>
          We’ve received your inquiry and are thrilled to assist you. Our team
          is reviewing your request and will get back to you within 24-48 hours.
        </p>
        <div className="call-btn-wrapper">
          <Link to="/home" className="call-btn">
            <button className="primary-btn">Home</button>
          </Link>
        </div>
      </div>
    </section>
  );
}
