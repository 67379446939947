import React from "react";

import DoubleCheck from "../../../Assets/icons/check-double.svg";
import Cross from "../../../Assets/icons/close.svg";
// import ProductImg from "../../../Assets/images/product-img.png";
import "./addToCart.scss";

import { useStateValue } from "../../../StateProvider";
import { Link, useNavigate } from "react-router-dom";
import { GenerateRefreshToken, RemoveItemFromCart } from "../../../apiCall";
import { toast } from "react-toastify";
import { domainName } from "../../../Constants";

const AddToCart = ({ id, description, product_img, title, price }) => {
  const navigate = useNavigate();
  const [, dispatch] = useStateValue();

  const setAddToCartModalShow = () => {
    dispatch({
      type: "SET_ADD_TO_CART_MODAL_SHOW",
      status: false,
    });
  };

  // console.log(id)

  const removeFromCart = async () => {
    const Re = await RemoveItemFromCart(id);
    // console.log(Re)

    if (Re?.data?.status[0].Error === "False") {
      setAddToCartModalShow();
    } else {
      if (Re?.data?.status) {
        toast.error(Re?.data?.status[0].ResponseMessage);
      } else {
        if (
          Re?.response?.data?.detail ===
          "Unauthenticated User, Please login first."
        ) {
          const refreshRe = await GenerateRefreshToken();

          console.log(refreshRe, "REFRESH RE");

          if (refreshRe?.data?.access_token) {
            localStorage.setItem(
              "refresh_token",
              refreshRe?.data?.refresh_token
            );
            localStorage.setItem("token", refreshRe?.data?.access_token);
            removeFromCart();
          } else {
            localStorage.clear();
            dispatch({
              type: "SET_USER_LOGGED_IN",
              status: false,
            });
          }
        } else {
          toast.error("Something went wrong, Please try agian.");
        }
      }
    }
  };
  return (
    <div className="add-to-cart-main">
      <div className="header">
        <img
          src={Cross}
          className="cross"
          alt=""
          onClick={() => setAddToCartModalShow(false)}
        />
        <img src={DoubleCheck} alt="" />
        <p>Product Added To Your Cart!</p>
      </div>
      <div className="body">
        <p>{description}</p>
        <div className="products-container">
          <div className="product-info">
            <div className="product-name">
              <img src={domainName + product_img} alt="" />
              <h3>{title}</h3>
            </div>
            <span className="price">
              <p className="amount">₹{price}</p>
              <p className="remove" onClick={removeFromCart}>
                Remove
              </p>
            </span>
          </div>
        </div>
        <div className="buttons">
          <button className="primary" onClick={() => setAddToCartModalShow()}>
            Continue
          </button>
          <Link to="/cart">
            <button
              className="secondary"
              onClick={() => {
                setAddToCartModalShow();
              }}
            >
              View Cart
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AddToCart;
